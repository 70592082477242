<template>
<div class="block py-5" v-if="logs">
  <div class="block my-2" v-for="(log, num) in filtered" :key="num">
    <div class="notification has-background-primary-light">
      <!-- <div class="message-header"> -->
      <!-- </div> -->
      <div class="pb-2">{{ log.body }}</div>
      <div>
	<span class="is-capitalized has-text-grey is-size-6">{{ author(log) }}, </span>
	<span class="has-text-grey is-size-7 is-italic">{{ created(log) }}</span>
      </div>
      <!-- <div class="buttons"> -->
      <!--   <button class="button is-outlined is-danger is-small is-rounded"><i class="fas fa-trash"></i></button> -->
      <!--   <button class="button is-outlined is-info is-success is-small is-rounded"><i class="fas fa-edit"></i></button> -->
      <!-- </div> -->
    </div>
  </div>
</div>
</template>

<script>

function parseTs(ts) {
    const dtFormat = new Intl.DateTimeFormat('en-GB', {
        timeStyle: 'long',
        dateStyle: 'full',
        timeZone: 'UTC',
    });
    return dtFormat.format(new Date(ts * 1e3));
}

export default {
    props: ["logs"],
    methods: {
	author(log){
	    if(log.author){return log.author}else{return "Anonymous"}
	},
	created(log){
	    return parseTs(log.created)
	}
    },
    computed: {
        filtered() {
            return this.logs.filter(log => log.body)
        },
    }
}
</script>
