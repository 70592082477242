<template>
<div class="field">
  <textarea autofocus class="textarea" placeholder="New comment" v-model="body"></textarea>
</div>

<div class="buttons">
  <button class="button is-success" :disabled="!body.length" @click="submitPressed" >Submit Comment</button>
  <button class="button is-danger" :disabled="!body.length" @click="abortPressed">Cancel</button>
</div>
</template>

<script>
export default {
    props: ["ticket"],
    emits: ["abort", "submit"],
    data() {
	return {
	    body: ""
	}
    },
    methods: {
	abortPressed(){
            this.$emit('abort')
            this.body = ""
	},
	submitPressed(){
            if(this.body.length){
	        this.$emit(
		    "submit", {body: this.body}
	        )
                this.body = ""
            }
	},
    }
}
</script>
