import { Auth } from 'aws-amplify';


export async function signUp(userData) {
    console.log("running signUp...")
    try {
	const { user } = await Auth.signUp({
	    username: userData.email,
	    password: userData.password,
	    attributes: {
		email: userData.email,
		given_name: userData.given_name,
		family_name: userData.family_name,
	    }
	});
	console.log(user);
    } catch (error) {
	console.log('error signing up:', error);
    }
}

export async function confirmSignUp(userData) {
    try {
	const data = await Auth.confirmSignUp(userData.email, userData.code);
	console.log(data)
    } catch (error) {
	console.log('error confirming sign up', error);
    }
}

export async function signIn(userData, callback) {
    console.log("running signIn...")
    try {
	const user = await Auth.signIn(userData.email, userData.password);
	if (callback) { callback(user) }
    } catch (error) {
	console.log('error signing in:', error);
    }
}
