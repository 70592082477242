import { getUrl } from "./general.js"


function ifItems(data) {
    // HACK: switches between paginated and non paginated response
    console.log(data)
    if (data.items) {
	return data.items
    } else {
	return data
    }
}


function createTicket (project, ticket, callback) {
    let url = getUrl(`/projects/${project}/tickets/`)
    console.log(`fetching (post) ${url}...`)
    let data = {
	method: "POST",
	headers: {'Content-Type': 'application/json'},
	body: JSON.stringify(ticket),
    }
    fetch(url, data)
	.then(resp => resp.json())
	.then(callback)
	.catch(err => {console.log(err)})
}


// function updateTicket (project, id, data, callback) {
//     let url = getUrl(`/projects/${project}/${id}/`)
//     console.log(`fetching (update) ${url}...`)
//     let fetchData = {
// 	method: "PUT",
// 	headers: {'Content-Type': 'application/json'},
// 	body: JSON.stringify(data),
//     }
//     fetch(url, fetchData)
// 	.then(resp => resp.json())
// 	.then(callback)
// 	.catch(err => {console.log(err)})
// }


function deleteTicket (project, id, callback) {
    let url = getUrl(`/projects/${project}/tickets/${id}/`)
    console.log(`fetching (delete) ${url}...`)
    let data = {
	method: "DELETE",
	headers: {'Content-Type': 'application/json'},
    }
    fetch(url, data)
	.then(resp => resp.json())
	.then(callback)
	.catch(err => {console.log(err)})
}


function getTickets (project, callback, page=1, limit=3) {
    let url = getUrl(`/projects/${project}/tickets/`, {_page: page, _limit: limit})
    console.log(`fetching ${url}...`)
    fetch(url)
	.then(res => res.json())
	.then(ifItems)
	.then(callback)
	.catch(err => {console.log(err)})
}


function getTicket(project, id, callback) {
    let url = getUrl(`/projects/${project}/tickets/${id}/`)
    console.log(`fetching ${url}...`)
    fetch(url)
	.then(res => res.json())
	.then(callback)
	.catch(err => {console.log(err)})
}


function getTicketLogs(project, id, callback) {
    let url = getUrl(`/tickets/${id}/logs/`)
    console.log(`fetching ${url}...`)
    fetch(url)
	.then(res => res.json())
	.then(callback)
	.catch(err => {console.log(err)})
}


function createTicketLog(ticket, log, callback=null) {
    let url = getUrl(`/tickets/${ticket.id}/logs/`)
    console.log(`fetching (post) ${url}...`)
    let data = {
	method: "POST",
	headers: {'Content-Type': 'application/json'},
	body: JSON.stringify({body: log.body}),
    }
    console.log("createTicketLog dat:", data)
    console.log("createTicketLog url:", url)
    fetch(url, data)
    	.then(resp => resp.json())
    	.then((data) => {if (callback){ callback(data) }})
    	.catch(err => {console.log(err)})
}



export {
    getTicket,
    getTickets,
    createTicket,
    // updateTicket,
    createTicketLog,
    deleteTicket,
    getTicketLogs,
};
