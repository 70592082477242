<template>
<div class="section">
  <TimerGame />
</div>
</template>


<script>
import TimerGame from '@/components/TimerGame.vue'

export default {
    name: 'TimerGameView',
    components: {
	TimerGame,
    },
}
</script>
