<template>

<div v-if="logged" class="section py-0 px-0">
  <Nav />
</div>

<section class="section py-7">
  <router-view />
</section>


<section v-if="logged" class="section has-background-primary-light">
  <Footer />
</section>

</template>

<script>
import Nav from './components/Nav.vue'
import Footer from './components/Footer.vue'

export default {
    components: {
	Nav,
	Footer,
    },
    created(){
	this.$store.dispatch('initialiseStore')
    },
    computed: {
	claims () {
	    return this.getClaims()
	},
	logged() {
	    return this.$store.getters["auth/exp"] > (new Date().getTime() + 1) / 1000
	},
    }
}
</script>

<style>
</style>
