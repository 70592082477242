import { signIn } from '@/aws/auth.js'


function parseJwt (token) {
    if (!token) { return null }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
	return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};


export default {
    namespaced: true,
    state: () => ({
	user: null,
	idToken: null,
	version: 1,
    }),
    mutations: {
	INITIALISE(state) {
	    if(localStorage.getItem('lilacpond:store:auth')) {
		const oldState = JSON.parse(localStorage.getItem('lilacpond:store:auth'))
		if (state.version === oldState.version){
		    this.replaceState(
			Object.assign(state, oldState)
		    );
		}
	    }
	},
	LOGGED_COGNITO(state, loginData) {
	    state.user = loginData.username
	    state.idToken = loginData.signInUserSession.idToken.jwtToken
	},
	LOGOUT(state) {
	    state.idToken = null
	},
	RESET_USER(state, user){
	    state.user = user
	}
    },
    actions: {
	login(context, payload){
	    signIn(
		{email: payload.creds.user, password: payload.creds.password},
		(resp) => {
		    context.commit("LOGGED_COGNITO", resp)
		    if(payload.callback){payload.callback()}
		},
	    )
	},
	logout(context){
	    context.commit("LOGOUT")
	},
	check(context){
	    if (!context.getters.isLogged) {
		context.commit("LOGOUT")
	    }
	},
	resetUser(context, user){
	    context.commit("RESET_USER", user)
	},
    },
    getters: {
	lastUser(state){
	    return state.user
	},
	loggedUser(state){
	    if (state.idToken){
		return state.user
	    }
	},
	exp(state){
	    if (state.idToken){
		const exp = parseJwt(state.idToken).exp
		return exp
	    } else {return -1}
	},
	remaining(state){
	    if (state.idToken){
		const now = (new Date().getTime() + 1) / 1000
		const exp = parseJwt(state.idToken).exp
		return exp - now
	    } else {return -1}
	},
	isLogged(state){
	    if (state.idToken){
		return parseJwt(state.idToken).exp > (new Date().getTime() + 1) / 1000
	    } else {return false}
	},
	claims(state){
	    if (state.idToken){
		return parseJwt(state.idToken)
	    }
	},
    },
}
