<template>


<div class="field">
  <label class="label">Email</label>
  <input class="input" type="email" required v-model="email" @keyup="keyUpHandler">
</div>

<div class="field">
  <label class="label">Password</label>
  <input class="input" type="password" required v-model="password" @keyup="keyUpHandler">
</div>

<div class="field">
  <label class="label">Given Name</label>
  <input class="input" type="text" required v-model="given_name" @keyup="keyUpHandler">
</div>

<div class="field">
  <label class="label">Family Name</label>
  <input class="input" type="text" required v-model="family_name" @keyup="keyUpHandler">
</div>

<!-- <label>Role</label> -->
<!-- <select v-model="role"> -->
<!--   <option value="admin">Admin</option> -->
<!--   <option value="user">User</option> -->
<!-- </select> -->

<div class="control py-5">
  <label class="ckeckbox">
    <input type="checkbox" v-model="terms">
    Accept terms and consitions
  </label>
</div>


<div class="py-1">
  <a class="a is-ghost">
    <router-link :to="{name: 'LoginStage', params: {stage: 'signin'}}" >
      You already have an account? Sign in...
    </router-link>
  </a>
</div>


<div class="py-1">
  <a class="a is-ghost">
    <router-link :to="{name: 'LoginStage', params: {stage: 'confirm'}}" >
      Confirm Code
    </router-link>
  </a>
</div>

<br>
<button class="button" @click="submitClicked">Submit</button>

<div v-if="warning" class="warning">
  Form not completed yet<span v-if="warningMessage"> ({{ warningMessage }})</span>...
</div>
</template>


<script>
import { signUp } from '@/aws/auth.js'

export default {
    data() {
	return {
	    email: "",
	    password: "",
	    given_name: "",
	    family_name: "",
	    role: "user",
	    terms: false,
	    warning: false,
	    warningMessage: "",
	}
    },
    created(){
	this.email = this.$store.getters["auth/lastUser"]
    },
    emits: [ "submitted" ],
    methods: {
	validate() {
	    if (this.password.length < 3){
		this.warning = true
		this.warningMessage = "Password too short"
		return null
	    }
	    if (this.email.length < 2){
		this.warning = true
		this.warningMessage = "Email too short"
		return null
	    }
	    if (!this.email.includes("@")){
		this.warning = true
		this.warningMessage = "Malformatted email"
		return null
	    }
	    if (this.given_name.length < 2){
		this.warning = true
		this.warningMessage = "Given name too short"
		return null
	    }
	    if (this.family_name.length < 2){
		this.warning = true
		this.warningMessage = "Family name too short"
		return null
	    }
	    return true
	},
	submitClicked() {
	    if (!this.validate()) {console.log("validation failed..."); return null}
	    var data = {
		email: this.email,
		password: this.password,
		role: this.role,
		terms: this.terms,
		family_name: this.family_name,
		given_name: this.given_name,
	    }
	    const promise = signUp(data)
	    this.$emit("submitted", {data: data, promise: {signup: promise}})
	},
	keyUpHandler (e) {
	    if ( e.type != "keyup") {
		return null
	    }
	    if ( e.key != "Enter") {
		return null
	    }
	    console.log("enter pressed!")
	    this.submitClicked()
	},
    },
}
</script>

<style>

</style>
