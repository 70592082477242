import { getUrl, ifItems } from "@/utils/general"


export default {
    namespaced: true,
    state: () => ({
	selected: null,
    }),
    mutations: {
	SET_SELECTED (state, project) {
	    state.selected = project
	},
    },
    actions: {
	setSelected(context, project){
	    context.commit('SET_SELECTED', project)
	},
    },
    getters: {
	getSelected(state){
	    if (state.selected){
		return state.selected
	    } else {
		return {name: "default"}
	    }
	},
	getSelectedName(state){
	    if (state.selected){
		return state.selected.name
	    }
	},
    },
}
	    
