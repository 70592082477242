<template>
<nav class="navbar has-background-primary-light px-5">

  <div class="navbar-brand">
    <a class="navbar-item">
      <router-link :to="{name: 'Home'}">
	<img
	  src="@/assets/imgs/logo.png"
	  style="max-height: 50px"
	  class="px-2 py-2"
	  >
      </router-link>
    </a>
    <a class="navbar-burger" aria-label="menu" aria-expanded="false" @click="burgerClicked" >
      <span class="icon is-large" style="background-color: transparent">
      	<i class="fas fa-bars fa-lg" ></i>
      </span>
    </a>
  </div>

  <div class="navbar-menu" :class="{'is-active': this.burger}">
    <div class="navbar-start">
      <div class="navbar-item title has-text-primary-dark">Lilac Pond</div>
    </div>
    <div class="navbar-end">
      <div v-if="logged"  class="navbar-item">
	<button 
	  class="button is-info" 
	  @click="projectModalActive = true"
	  >{{ project }}</button>
	<ModalSimple :active="projectModalActive" @closed="projectModalActive = false">
	  <template v-slot:title>Manage Projects</template>
	  <ProjectManager />
	</ModalSimple>
      </div>
      <div class="navbar-item is-size-6 is-italic">{{ user }}</div>
      <div class="navbar-item" v-if="logged">
  	<router-link
  	  :to="{name: 'Login'}"
  	  @click="logoutClicked"
  	  class="singout">
  	  Sign out
  	</router-link>
      </div>
    </div>
  </div>

</nav>
</template>


<script>
import ModalSimple from '@/components/modals/ModalSimple.vue'
import ProjectManager from '@/components/projects/ProjectManager.vue'

export default {
    name: 'Nav',
    components: { ModalSimple, ProjectManager },
    data () {
	return {
	    burger: false,
	    projectModalActive: false,
	}
    },
    methods: {
	logoutClicked() {
	    console.log("logging out...")
	    this.$store.dispatch('auth/logout')
	},
	burgerClicked() {
	    this.burger = !this.burger
	}
    },
    computed: {
	user() {
	    let usr = this.$store.getters['auth/loggedUser']
	    if (usr) { return usr }
	},
	logged() {
	    return this.$store.getters['auth/isLogged']
	},
	project() {
	    return this.$store.getters["projects/getSelected"].name
	}
    }
}
</script>

<style>
</style>
