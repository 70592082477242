import { createStore } from 'vuex'

import projects from './modules/projects'
import auth from './modules/auth'


const storeKey = 'lilacpond:store'


const store = createStore({
    modules: {
	auth: auth,
	projects: projects
    },
    store: {
	version: 1,
    },
    actions: {
	initialiseStore(state) {
	    if(localStorage.getItem(storeKey)) {
		this.replaceState(
		    Object.assign(state, JSON.parse(localStorage.getItem(storeKey)))
		)
	    }
	}
    },
})

const unsubscribe = store.subscribe((mutation, state) => {
    localStorage.setItem(storeKey, JSON.stringify(state));
})

export default store ;
