<template>
<div class="section">
  <PosBox />
</div>
</template>


<script>

import PosBox from '@/components/PosBox.vue'

export default {
    components: {
	PosBox,
    },
}
</script>
