<template>
<div class="box" :class="{clickme: isActive()}" @click="clicked">
  <div v-if="isActive()" >Click Me ({{ offset }} / {{ this.elapsed }})</div>
  <div v-else>Wait for it..... ({{ offset }})</div>
</div>
</template>

<script>
export default {
    name: 'Clicker',
    data() {
	return {
	    offset: 0,
	    elapsed: -1,
	    timer: null,
	}
    },
    methods: {
	isActive() {
	    return this.elapsed > 0
	},
	clicked() {
	    if (this.isActive()){
		clearInterval(this.timer)
		var ret = this.elapsed
		this.elapsed = -1
		this.$emit("stopped", {"elapsed": ret})
	    } else {
		console.log("premature...")
	    }
	}
    },
    mounted() {
	this.offset = 2000 + Math.random() * 4000
	setTimeout(
	    () => {this.timer = setInterval(() => {this.elapsed += 10}, 10)},
	    this.offset)
    }
}
</script>

<style>
.box.clickme {
    background: orange;
    colour: white
}
</style>
