// bulma + scss + sass
import './assets/global.scss'


// fontawesome
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { dom } from "@fortawesome/fontawesome-svg-core"

library.add(fas);
library.add(fab);
library.add(far);
dom.watch();


// amplify/AWS
import Amplify from 'aws-amplify';
import { getConfig } from './aws/config.js'
Amplify.configure({
    Auth: getConfig()
})


// setup app
import { createApp } from 'vue'
import router from './router'
import store from './store'

import App from './App.vue'
const app = createApp(App)
app.component("font-awesome-icon", FontAwesomeIcon)
app.use(router)
app.use(store)
app.mount('#app')
