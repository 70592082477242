<template>
<div class="card" :class="{'has-background-danger-light': deleted}">

  <div class="card-content">
    <div class="media">
      <div class="media-left">
	<figure class="image is-48x48">
	  <img :src="ticket.image" alt="Placeholder image">
	</figure>
      </div>
      <div class="media-content">
	<p class="title is-4">{{ ticket.name }}</p>
	<p class="subtitle is-6">{{ ticket.owner }}</p>
      </div>
    </div>

    <div class="content" >
      <!-- <p>Ticket ID: {{ ticket.id }}</p> -->
      <p>{{ ticket.short }}</p>
    </div>
  </div>

  <footer class="card-footer">
    <!-- <a class="card-footer-item" data-tooltip="Tooltip Text"> -->
    <!--   <input type="checkbox" @click="handleSelect" v-model="selected"> -->
    <!-- </a> -->
    <a class="card-footer-item" >
      <router-link :to="{name: 'TicketDetails', params: {id: ticket.id}}">
	<i class="fas fa-edit"></i>
      </router-link>
    </a>

    <ModalSimple :active="commentActive" @closed="commentActive = false">
      <template v-slot:title>Quick Card Comment</template>
      <TicketComment
	:ticket="ticket"
	@abort="commentActive = false"
	@submit="submitComment"
	/>
    </ModalSimple>
    <a
      class="card-footer-item "
      @click="commentActive = true"
      >
      <i class="fas fa-comment"></i>
    </a>
    <a class="card-footer-item" @click="handleFooterItem('split', ticket)">
      <i class="fas fa-clone"></i>
    </a>
    <a class="card-footer-item" @click="handleFooterItem('delete', ticket)">
      <span class="icon has-text-danger">
	<i v-if="deleted" class="fas fa-sync fa-spin"></i>
	<i class="fas fa-trash "></i>
      </span>
    </a>
  </footer>

</div>

</template>

<script>
import {deleteTicket, createTicketLog} from '@/utils/tickets.js'

import ModalSimple from '@/components/modals/ModalSimple.vue'
import TicketComment from '@/components/tickets/TicketComment.vue'


export default {
    props: [ "ticket" ],
    events: [ "clicked" ],
    components: {ModalSimple, TicketComment},
    data() {
	return {
	    selected: false,
	    deleted: false,
	    commentActive: false,
	}
    },
    methods: {
	clickedCard(){
	    this.$emit("clickedCard", this.ticket)
	},
	handleFooterItem(action){
	    console.log("Handle action: " + action)
	    if (action == "delete") {
		this.deleted = true
		deleteTicket(
		    this.$store.getters["projects/getSelected"].id,
		    this.ticket.id, 
		    () => {this.$emit("deleted", this.ticket)})
	    }
	},
	handleSelect(){
	    console.log("Handle Selected")
	    console.log(this.ticket.id)
	    console.log(this.selected)
	},
	submitComment(event){
	    console.log("submit event:", event)
	    createTicketLog(
		this.ticket, 
		{body: event.body},
		(data) => this.commentActive = false
	    )
	},
    },
}
</script>

<style scoped>
img {
    border-radius: 50%;
    width: 100px
}

div.ticketcard {
    display: inline-block;
    border-style: solid;
    border-color: #aaa;
    border-width: 1px;
    border-radius: 7px;
    margin: 10px ;
    padding: 20px;
    width: 300px;
    background: #f4f4f4;
    cursor: pointer;
}
div.ticketcard { background: #ddd }
div.title {
    display: flex;
    align-items: center;
}

.ticketcard span {
    display: inline-block
}
.ticketcard h3 {
    display: inline-block;
    padding: 30px;
    color: #449;
}
</style>
