function ifItems(data) {
    // HACK: switches between paginated and non paginated response
    console.log(data)
    if (data.items) {
	return data.items
    } else {
	return data
    }
}


function getUrl(endpoint, query=null) {
    const url_stripped = process.env.VUE_APP_API_URL.replace(/\/$/, '')
    const endpoint_stripped = endpoint.replace(/^\//, '')
    let url = new URL(`${url_stripped}/${endpoint_stripped}`)
    if (query) {
	let qs = new URLSearchParams(query)
	url.search = qs.toString()
    }
    return url.href
}

function chunkArray(bigArray, size){
    var arrayOfArrays = [];
    for (var i=0; i < bigArray.length; i+=size) {
	arrayOfArrays.push(bigArray.slice(i, i + size));
    }
    return arrayOfArrays
}


export { ifItems, getUrl, chunkArray }
