export function getConfig () {
    return {
	region: 'us-east-1',
	userPoolId: 'us-east-1_luLuOtOtj',
	userPoolWebClientId: '76ulnjrmfcpsn6gksepgq9vo3t',
	// cookieStorage: {
	//     domain: '.lilacpond.net',
	//     path: '/',
	//     expires: 365,
	//     sameSite: "strict",
	//     secure: true
	// },
	authenticationFlowType: 'USER_PASSWORD_AUTH',
    }
}

export function getSigninFormFields() {
    return [
	{
	    type: 'email',
	    label: 'Custom Email Label',
	    placeholder: 'Custom email placeholder',
	    inputProps: { required: true, autocomplete: 'username' },
	},
	{
	    type: 'password',
	    label: 'Custom Password Labellll',
	    placeholder: 'Custom password placeholder',
	    inputProps: { required: true, autocomplete: 'new-password' },
	},
    ]
}

export function getSigninConfig () {
    return {
	signUpConfig: {
	    header: 'Sign up for an account',
	    hiddenDefaults: ['phone_number', 'username'],
	    signUpFields: [
		{
		    label: 'Email',
		    key: 'email',
		    type: 'string',
		    required: true,
		    displayOrder: 0
		},
		{
		    label: 'Password',
		    key: 'password',
		    type: 'password',
		    required: true,
		    displayOrder: 1
		}
	    ]
	},
	confirmSignUpConfig: {
	    header: 'Please enter the code sent to your email'
	},
    }
}
