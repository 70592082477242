<template>
<div class="block">
  Select Project: <div class="tag is-large is-info has-text-weight-bold">{{ selected.name }}</div>
</div>
<div class="field">
  <label class="label">Name</label>
  <input class="input" type="text" required v-model="name">
</div>
<div class="field">
  <label class="label">Brief Description</label>
  <input class="input" type="text" required v-model="brief">
</div>

<div class="field">
  <label class="label">Owner</label>
  <div class="select">
    <select v-model="owner">
      <option v-for="useropt in users" :key="useropt.id" :value="useropt.id">{{ useropt.name }}</option>
    </select>
  </div>
</div>
<button class="button is-primary mt-4" @click="submitClicked">Submit</button>
<div v-if="warning" class="has-text-danger is-italic">
  Error: {{ warning }}...
</div>
</template>

<script>
import { createTicket } from "@/utils/tickets.js"

export default {
    props: {
	post: {
	    default: true,
	    type: Boolean,
	},
	users: {
	    type: Object,
	    default: [
		{name: "Lorenzo", id: "lorenzo-id"},
		{name: "Heather", id: "heather-id"},
		{name: "Violet", id: "violet-id"},
		{name: "Rosa", id: "rosa-id"},
	    ],
	},
    },
    data() {
	return {
	    name: "",
	    brief: "",
	    image: "https://www.w3schools.com/howto/img_avatar.png",
	    warning: "",
	    owner: "",
	}
    },
    created() {
    	console.log("creating...")
    },
    methods: {
	validate() {
	    console.log("hi...")
	    if (this.name.length < 3){
		this.warning = "provide a name"
		return false
	    }
	    return true
	},
	submitClicked() {
	    if (!this.validate()){
		return null
	    }
	    const data = {
		id: this.id,
		name: this.name,
		owner: this.owner,
		brief: this.brief,
		image: this.image,
	    }
	    if (this.post){
		console.log("posting...")
		createTicket(
		    this.selected.id,
		    data,
		    (resp) => { this.$emit("created", resp) })
	    } else {
		this.$emit("created", data)
	    }
	},
    },
    computed: {
	selected(){return this.$store.getters["projects/getSelected"]}
    },
}
</script>
