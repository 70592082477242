<template>
<!-- <Auth :stage="stage" /> -->

<div class="columns">
  <div class="card column is-half is-offset-one-quarter has-background-light has-shadow">
    <span v-if="stage === 'nostage'"><h3>No stage?</h3></span>
    <span v-else-if="stage === 'signin'">
      <h2 class="title">SignIn</h2>
      <SignIn
	@submitted="submittedSignIn"
	/>
    </span>
    <span v-else-if="stage === 'signup'">
      <h2 class="title">SignUp</h2>
      <SignUp
	@submitted="submittedSignUp"
	/>
    </span>
    <span v-else-if="stage === 'confirm'">
      <h2 class="title">Confirm</h2>
      <Confirm
	@submitted="submittedConfirm"
	/>
    </span>
    <span v-else>
      <h3 class="subtitle">Not Understood </h3>
      <p>Stage: {{ stage }}</p>
    </span>
  </div>
</div>
</template>

<script>
import SignIn from '@/components/auth/SignIn.vue'
import SignUp from '@/components/auth/SignUp.vue'
import Confirm from '@/components/auth/Confirm.vue'


export default {
    components: { SignUp, SignIn, Confirm },
    methods: {
	getStage() {
	    if (this.$route.params.stage) {
		return this.$route.params.stage
	    } else {
		return "signin"
	    }
	},
	submittedSignIn(data) {
	    console.log("SignIn submitted...")
	    this.$router.push({name: "Home"})
	},
	submittedSignUp(data) {
	    console.log("SignUp submitted...")
	    this.$router.push({
		name: "LoginStage",
		params: {stage: "confirm"},
	    })
	},
	submittedConfirm(data) {
	    console.log("confirm submitted...")
	    this.$router.push({
		name: "LoginStage",
		params: {stage: "signin"},
	    })
	},
    },
    computed: {
	stage() { return this.getStage() },
    }
}
</script>
