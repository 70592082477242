<template>
<div class="modal" :class="{'is-active': active}">
  <div class="modal-background"></div>
  <div class="modal-content">

    <header class="modal-card-head">
      <p class="modal-card-title"><slot name="title"></slot></p>
      <button class="delete" aria-label="close" @click="closeClicked"></button>
    </header>

    <section class="modal-card-body ">
      <span v-if="active"><slot></slot></span>
    </section>

  </div>
</div>
</template>


<script>
export default {
    name: "ModalSimple",
    props: ["active"],
    emits: ["closed"],
    methods: {
	closeClicked () {
	    this.$emit("closed")
	}
    },
    computed: {
	hasTitle() {
	    titleSlot = this.$slots.title
	    if (!titleSlot) {
		return false
	    }
	    if (!titleSlot().length) {
		return false
	    }
	    return True
	}
    }
}
</script>
