import { createWebHistory, createRouter } from "vue-router";

// logged
import store from '@/store'

// views
import Home from '@/views/Home.vue'
import TimerGameView from '@/views/TimerGameView.vue'
import PosBoxView from '@/views/PosBoxView.vue'
import LoginView from '@/views/LoginView.vue'
import TicketsView from '@/views/TicketsView.vue'
import TicketSingleView from '@/views/TicketSingleView.vue'
import PageNotFound from '@/views/PageNotFound.vue'


const routes = [
    {
	path: '/',
	name: 'Home',
	component: Home,
    },
    {
	path: '/tickets',
	name: 'Tickets',
	component: TicketsView,
    },
    {
	path: '/tickets/:id',
	name: 'TicketDetails',
	component: TicketSingleView,
    },
    {
	path: '/login',
	name: 'Login',
	component: LoginView,
    },
    {
	path: '/login/:stage',
	name: 'LoginStage',
	component: LoginView,
    },
    {
	path: '/timer',
	name: 'TimerGameView',
	component: TimerGameView,
    },
    {
	path: '/posbox',
	name: 'PosBox',
	component: PosBoxView,
    },
    {
	path: '/:pathMatch(.*)*',
	name: 'PageNotFound',
	component: PageNotFound },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


function  redirectWww(){
    let url = (new URL(window.location.href));
    if (url.hostname === "lilacpond.net"){
        console.log("replacing location...")
        url.hostname = "www.lilacpond.net"
        window.location.replace(url.href)
    }
}


router.beforeEach((to, from, next) => {

    console.log("router: ", window.location.href)
    redirectWww()

    if (! store.getters["auth/isLogged"]){
	console.log("user not logged in, dispatching...")
	store.dispatch("auth/logout")
    }
    if (to.name === 'PageNotFound') {
	console.log("PageNotFound route is always ok")
	// prevent a redirect loop
	next()
    } else if (to.name === 'Login' || to.name === "LoginStage") {
	console.log("login route is always ok")
	// prevent a redirect loop
	next()
    } else if (to.meta && to.meta.requiresAuth === false) {
	console.log("requires auth is explicitly set to false")
	next()
    } else if (store.getters["auth/isLogged"]) {
	// i'm logged in. carry on
	next()
    } else {
	console.log("redirect to login page... " + to.name)
	next({ name: 'Login'}) // always put your redirect as the default case
    }
})

export default router
