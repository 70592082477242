import { reactive, readonly } from 'vue'

const Loaded = reactive({
    user: null,
    token: null,
})

function parseJwt (token) {
    if (!token) { return null }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
	return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};

function getLast(key) {
    let last = localStorage.getItem(key)
    if (last === "undefined" || last === "null") {
	return null
    }
    return last
}

const getters = {
    getToken () {
	if (Loaded.token) {
	    return Loaded.token
	} else {
	    return getLast('last-token')
	}
    },
    getUser () {
	if (Loaded.user) {
	    return Loaded.user
	} else {
	    return getLast('last-user')
	}
    },
    getClaims () {
	let token = getters.getToken()
	if (token) {
	    return parseJwt(token)
	} else {
	    return {}
	}
    },
}

const actions = {
    setUser(user){
	Loaded.user = user
	localStorage.setItem('last-user', user)
    },
    setToken(token){
	Loaded.token = token
	localStorage.setItem('last-token', Loaded.token)
    },
    fromCognito(data) {
	console.log("fromCognito called...")
	console.log(data)
	actions.setUser(data.username)
	actions.setToken(data.signInUserSession.idToken.jwtToken)
    },
    logout () {
	Loaded.token = null
	localStorage.setItem('last-token', null)
    },
    isLogged() {

	if (!getters.getUser()) {return false}
	if (!getters.getToken()) {return false}

	const claims = getters.getClaims()
	if (!claims) {return false}

	const now = new Date().getTime() / 1000
	if (claims.exp - now < 0) {
	    actions.setToken(null)
	    return false
	}
	return true
    }
}

export default () => ({
    state: readonly(Loaded),
    ...getters,
    ...actions,
})
