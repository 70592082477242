<template>
<button class="button" @click="toggleShow">{{ button }}</button>
<ModalSimple :active="showModal" @closed="toggleShow">
  <template v-slot:title>{{ title }}</template>
  <p>{{ message }}</p>
</ModalSimple>
</template>

<script>
import ModalSimple from './ModalSimple.vue'

export default {
    name: "ModalButton",
    components: {
	ModalSimple,
    },
    data() {
        return {
            showModal: false
        }
    },
    props: {
	button: {
	    type: String,
	    default: "Button",
	},
	title: {
	    type: String,
	    default: "Title",
	},
	message: {
	    type: String,
	    default: "Message",
	},
    },
    methods: {
        toggleShow(event){
            this.showModal = !this.showModal
        }
    }
}
</script>


<style>

</style>
