import { getUrl } from "./general.js"

function fetchProjects(page=1, limit=10) {
    let url = getUrl("/projects/", {_page: page, _limit: limit})
    console.log(`fetching ${url}...`)
    return fetch(url)
	.then(res => res.json())
	.catch(err => {console.log(err)})
}

function postProject(project) {
    let url = getUrl("/projects/")
    let fetchData = {
	method: "POST",
	headers: {'Content-Type': 'application/json'},
	body: JSON.stringify(project),
    }
    console.log(`fetching ${url}...`)
    return fetch(url, fetchData)
	.then(res => res.json())
	.catch(err => {console.log(err)})
}


export { fetchProjects, postProject }
