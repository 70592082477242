<template>
<div class="field">
  <label class="label">Email</label>
  <input class="input" type="email" required v-model="email" @keyup="keyUpHandler">
</div>
<div class="field">
  <label class="label">Confirmation Code</label>
  <input class="input" type="text" required v-model="code" @keyup="keyUpHandler">
</div>

<div class="py-1">
  <a class="a is-ghost">
    <router-link :to="{name: 'LoginStage', params: {stage: 'signin'}}" >
      Sign in instead
    </router-link>
  </a>
</div>
<div class="py-5">
  <button class="button" @click="submitClicked">Submit</button>
</div>
<div v-if="warning" class="warning">
  Confirmation Failed<span v-if="warningMessage">: {{ warningMessage }}</span>
</div>
</template>

<script>
import { confirmSignUp } from '@/aws/auth.js'


export default {
    data() {
	return {
	    email: "",
	    code: "",
	    warning: false,
	    warningMessage: "",
	}
    },
    emits: [ "submitted" ],
    created () {
	this.email = this.$store.getters["auth/lastUser"]
    },
    methods: {
	validate() {
	    if (this.email.length < 2){
		this.warning = true
		this.warningMessage = "Email too short"
		return null
	    }
	    if (!this.email.includes("@")){
		this.warning = true
		this.warningMessage = "Malformatted email"
		return null
	    }
	    if (this.code.length < 6){
		this.warning = true
		this.warningMessage = `Code too short ${this.code.length}`
		return null
	    }
	    return true
	},
	submitClicked() {
	    if (!this.validate()) {
		console.log("validation failed...")
		this.warning = true
		return null
	    }
	    var data = {
		email: this.email,
		code: this.code,
	    }
	    const promise = confirmSignUp(data)
	    this.$emit("submitted", {data: data, promise: {signup: null}})
	},
	keyUpHandler (e) {
	    if ( e.type != "keyup") {
		return null
	    }
	    if ( e.key != "Enter") {
		return null
	    }
	    console.log("enter pressed!")
	    this.submitClicked()
	},
    },
}
</script>

<style>
.pill {
    display: inline-block;
    padding: 6px;
    background: #88a;
    margin: 3px;
    border: 1px solid #224;
    color: #eee;
    border-radius: 8px;
}
</style>
