<template>
<div class="level">
  <div class="level-left has-text-primary-dark">&copy; All Right Reserved</div>
  <div class="level-right">
    <ModalButton
      :button="button"
      :title="title"
      :message="message"
      />
  </div>
</div>
</template>


<script>
import ModalButton from '@/components/modals/ModalButton.vue'

export default {
    name: 'Footer',
    data() {
	return {
	    "title": "Credits",
	    "button": "Credits",
	    "message": "Il Profeta",
	}
    },
    components: {
	ModalButton,
    },
}
</script>

<style scoped>
</style>
