<template>
<TicketSingle :ticket="ticket" :logs="logs" @submitComment="handleSubmitComment"/>
</template>

<script>
// single components
import TicketSingle from '@/components/tickets/TicketSingle.vue'
import { getTicket, getTicketLogs, createTicketLog } from "@/utils/tickets.js"

export default {
    components: { TicketSingle },
    data(){
	return {
	    ticket: {},
	    logs: [],
	    log_page: 1,
	}
    },
    created() {
	this.updateTicket(this.$route.params.id)
	this.updateTicketLogs(this.$route.params.id)
    },
    methods: {
	updateTicket(id) {
	    console.log(`updating... ${id}`)
	    if (!id){id = this.$route.params.id}
	    getTicket(
		this.$store.getters["projects/getSelected"].id, id,
		(ticket) => {
		    this.ticket = ticket
		})
	},
	updateTicketLogs(id) {
	    console.log(`updating logs... ${id}`)
	    if (!id){id = this.$route.params.id}
	    getTicketLogs(
		this.$store.getters["projects/getSelected"].id, id,
		(page) => {
		    this.logs = page.items
		})
	},
        handleSubmitComment(event){
	    console.log("submit event:", event, this.$route.params.id)
	    createTicketLog(
	        this.ticket,
	        {body: event.body},
                (data) => {
                    console.log("submitted; response:", data)
                    this.updateTicketLogs(this.ticket.id)
                }
	    )
	},
    }
}
</script>
