<template>
<nav class="level">

  <!-- <\!-- Left side -\-> -->
  <!-- <div class="level-left"> -->
  <!--   <div class="level-item"> -->
  <!--     <div class="field has-addons"> -->
  <!-- 	<p class="control"> -->
  <!-- 	  <input @keyup="handleSearchKey" class="input" type="text" placeholder="Find a ticket"> -->
  <!-- 	</p> -->
  <!-- 	<p class="control"> -->
  <!-- 	  <button @click="handleSearchClick" class="button"> -->
  <!-- 	    Search -->
  <!-- 	  </button> -->
  <!-- 	</p> -->
  <!--     </div> -->
  <!--   </div> -->
  <!-- </div> -->

  <!-- Right side -->
  <div class="level-right">
    <p class="level-item"><a @click="handleRefresh" class="button is-primary">Refresh</a></p>
    <p class="level-item">
      <a @click="toggleNew" class="button is-primary">
	New
      </a>
      <ModalSimple :active="newActive" @closed="toggleNew">
	<template v-slot:title>New Ticket</template>
	<TicketCreate :post="true" @created="handleCreated"/>
      </ModalSimple>
    </p>
  </div>
</nav>

<Tickets :tickets="tickets" @edited="updateTickets()" />

<nav class="level">
  <p class="level-item">
    <a class="icon" @click="changePage(-1)">
      <i class="fas fa-arrow-left "></i>
    </a>
  </p>
  <p class="level-item">{{ page }}</p>
  <p class="level-item">
    <a class="icon" @click="changePage(1)">
      <i class="fas fa-arrow-right "></i>
    </a>
  </p>
</nav>

</template>

<script>
import TicketCreate from '@/components/tickets/TicketCreate.vue'
import Tickets from '@/components/tickets/Tickets.vue'
import ModalSimple from '@/components/modals/ModalSimple.vue'

import { getTickets } from "@/utils/tickets.js"


export default {
    props: [ "mode" ],
    components: { Tickets, TicketCreate, ModalSimple },
    data () {
	return {
	    tickets: [],
	    page: 1,
	    limit: 6,
	    newActive: false,
	}
    },
    computed: {
	selected(){return this.$store.getters["projects/getSelected"]}
    },
    watch: {
	selected: function (val){
	    this.updateTickets(1)
	},
    },
    mounted () {
	this.updateTickets(this.page)
    },
    methods: {
	updateTickets(page) {
	    if (!page) {
		page = this.page
	    }
	    if (page < 1) {
		console.log("page must be positive non-zero int...")
		return null
	    }
	    getTickets(
		this.selected.id,
	     	tickets => {
	     	    if (tickets.length) {
	     		this.tickets = tickets
	     		this.page = page
	     	    } else {
			console.log("no tickets found...")
			if (page == this.page) {
			    console.log("page didn't change... setting tickets")
	     		    this.tickets = []
			    this.page = page
			    // this.updateTickets(page - 1)
			}
		    }
	     	},
	     	page,
	     	this.limit
	    )
	},
	handleSearchKey (e) {
	    console.log("search key pressed...")
	},
	handleSearchClick () {
	    console.log("search button pressed...")
	},
	toggleNew(e){
	    console.log("toggling new...")
	    this.newActive = !this.newActive
	},
	handleCreated(e){
	    console.log(`handling created... ${e}`)
	    this.toggleNew()
	    this.updateTickets()
	},
	handleRefresh(e){
	    this.updateTickets()
	},
	changePage(diff){
	    this.updateTickets(this.page + diff)
	},
    },
}
</script>
