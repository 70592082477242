<template>
<div><button @click="start" :disabled="gameOn">timer game</button></div>
<span v-if="gameOn">
  <Clicker @stopped="stop" />
</span>
<span v-else>
  <Results :score="score" />
</span>
</template>

<script>
import Clicker from './Clicker.vue'
import Results from './Results.vue'

export default {
    name: 'TimerGame',
    components: { Clicker, Results },
    data() {
	return {
	    gameOn: false,
	    score: -1,
	}
    },
    methods: {
	start() {
	    console.log("starting...")
	    this.gameOn = true
	},
	stop(data) {
	    console.log("stopping")
	    this.score = data.elapsed
	    this.gameOn = false
	},
    }
}
</script>
