<template>

<div class="message is-primary">

  <div class="message-header">
    <p>Select project</p>
    <span class="icon" v-if="showSelect" @click="showSelect=false"><i class="fas fa-angle-down"></i></span>
    <span class="icon" v-if="!showSelect" @click="showSelect=true"><i class="fas fa-angle-left"></i></span>
  </div>

  <div class="message-body" v-if="showSelect">
    <div class="block">
      Selected project: <div class="tag is-large is-info has-text-weight-bold">{{selected.name}}</div>
    </div>

    <div class="block">
      <label class="label">Select Project:</label>
      <button v-for="proj in projects" :key="proj.id" class="button icon-text is-info m-1">
	<span @click="toDelete=null; selectProject(proj)">{{ proj.name }}</span>
	<!-- <span class="icon" @click="toDelete=proj ; deleteNotImplemented=false"><i class="fas fa-times"></i></span> -->
      </button>
    </div>

    <article v-if="toDelete" class="message is-danger">
      <div class="message-header">
	<span>
  	  Confirm Delete Project:
  	  <span class="tag is-medium">{{ toDelete.name }}</span>
	</span>
	<button class="delete" @click="toDelete=null; deleteNotImplemented=false"></button>
      </div>
      <div class="message-body">
	<div class="buttons is-centered">
  	  <button class="button is-danger is-outlined" @click="deleteNotImplemented=true">
  	    <span class="icon is-small">
  	      <i class="fas fa-check"></i>
  	    </span>
  	    <span>Yes, delete please</span>
  	  </button>
	</div>
	<div class="block is-italic" v-if="deleteNotImplemented">Delete Not implemented...</div>
      </div>
    </article>

    <nav class="level pt-3">
      <p class="level-item">
	<a class="icon" @click="changePage(-1)">
	  <i class="fas fa-arrow-left "></i>
	</a>
      </p>
      <p class="level-item">{{ page }}</p>
      <p class="level-item">
	<a class="icon" @click="changePage(1)">
	  <i class="fas fa-arrow-right "></i>
	</a>
      </p>
    </nav>
  </div>

</div>

<div class="message is-primary">
  <div class="message-header">
    <p>Create new Project</p>
    <span class="icon" v-if="showCreate" @click="showCreate=false"><i class="fas fa-angle-down"></i></span>
    <span class="icon" v-if="!showCreate" @click="showCreate=true"><i class="fas fa-angle-left"></i></span>
  </div>
  <div class="message-body" v-if="showCreate">
    <div class="field">
      <label class="label">Create New Project</label>
      <input class="input" v-model="newProjectName">
      <span class="buttons">
	<button 
	  class="button mt-4" 
	  @click="confirmSubmit=true">
	  Submit</button>
	<button 
	  v-if="confirmSubmit" 
	  @click="newProjectSubmitted"
	  class="button is-success mt-4">
	  Confirm Submission</button>
	<button
	  v-if="confirmSubmit" 
	  @click="confirmSubmit=false"
	  class="button is-danger is-outlined mt-4">
	  Abort</button>
      </span>
    </div>
  </div>
</div>



</template>

<script>
import { fetchProjects, postProject } from '@/utils/projects.js'

export default {
    data() {
	return {
	    page: 1,
	    last: 1,
	    limit: 5,
	    projects: [],
	    newProjectName: "",
	    toDelete: null,
	    deleteNotImplemented: false,
	    confirmSubmit: false,
	    showSelect: true,
	    showCreate: false,
	}
    },
    created(){
	this.fetchNewPage(1, this.limit)
    },
    methods: {
	fetchNewPage(page, limit){
	    fetchProjects(page, limit)
		.then((page) => {
		    this.projects = page.items
		    this.limit = page.limit
		    this.last = page.last
		    this.page = page.page
		})
	},
	changePage(diff){
	    let newPage = this.page + diff
	    if (newPage > this.last) {newPage = this.last}
	    if (newPage < 1){newPage = 1}
	    this.fetchNewPage(newPage, this.limit)
	},
	selectProject(proj){
     	    console.log("setting selected project...")
	    this.$store.dispatch("projects/setSelected", proj)
	},
	newProjectSubmitted(){
	    if (this.newProjectName.length > 2){
     		console.log(`New Project Name submitted ${this.newProjectName}`)
		const project = {
		    name: this.newProjectName,
		    description: "hardcoded description",
		}
		postProject(project)
		    .then((data) => {
			console.log(data)
			this.confirmSubmit = false
			this.fetchNewPage(this.page, this.limit)
		    })
     	    }
     	},
	deleteProject(project){
	    console.log(`deleting project ${project.name}`)
	}
    },
    computed: {
	selected() {
	    return this.$store.getters['projects/getSelected']
	},
    }
}
</script>
