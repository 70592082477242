<template>
<div v-for="(tickets, group) in chunked" :key="`group-${group}`" class="columns">
  <div v-for="ticket in tickets" :key="ticket.id" class="column is-4">
    <TicketCard @deleted="handleTicketEvent" :ticket="ticket" />
  </div>
</div>
</template>

<script>
import { chunkArray } from "@/utils/general.js"
import TicketCard from "./TicketCard.vue"

export default {
    props: [ "tickets" ],
    components: { TicketCard },
    emits: ["edited"],
    methods: {
	clickedCard (ticket) {
	    console.log(ticket.id)
	},
	handleTicketEvent(e){
	    this.$emit("edited", e)
	}
    },
    computed: {
	chunked() {
	    return chunkArray(this.tickets, 3)
	}
    }
}
</script>

<style>
</style>
