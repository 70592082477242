<template>
<div class="field">
  <label class="label">Email</label>
  <input class="input" type="email" required v-model="email" @keyup="keyUpHandler">
</div>
<div class="field">
  <label class="label">Password</label>
  <input class="input" type="password" required v-model="password" @keyup="keyUpHandler">
</div>

<div class="py-1">
  <a class="a is-ghost">
    <router-link :to="{name: 'LoginStage', params: {stage: 'signup'}}" >
      No account? Sign up...
    </router-link>
  </a>
</div>

<div class="py-1">
  <a class="a is-ghost">
    <router-link :to="{name: 'LoginStage', params: {stage: 'confirm'}}" >
      Confirm Code
    </router-link>
  </a>
</div>

<div class="py-5">
  <button class="button" @click="submitClicked">Submit</button>
</div>
<div v-if="warning" class="warning">
  Login Failed<span v-if="warningMessage">: {{ warningMessage }}</span>
</div>

</template>


<script>
import { signIn } from '@/aws/auth.js'
import loginStateModule from '@/state/loginStateModule.js'

export default {
    data() {
	return {
	    email: "",
	    password: "",
	    warning: false,
	    warningMessage: "",
	}
    },
    emits: [ "submitted", "authswitch" ],
    setup () {
	const { state: loginState, fromCognito , getUser } = loginStateModule()
	return { loginState, fromCognito , getUser }
    },
    created () {
	this.email = this.$store.getters["auth/lastUser"]
    },
    methods: {
	validate() {
	    if (this.email.length < 2){
		this.warning = true
		this.warningMessage = "Email too short"
		return null
	    }
	    if (!this.email.includes("@")){
		this.warning = true
		this.warningMessage = "Malformed email"
		return null
	    }
	    if (this.password.length < 2){
		this.warning = true
		this.warningMessage = "Password too short"
		return null
	    }
	    return true
	},
	submitClicked() {
	    if (!this.validate()) {
		console.log("validation failed......")
		this.warning = true
		return null
	    }
	    const creds = {
		user: this.email,
		password: this.password
	    }
	    this.$store.dispatch('auth/login', {creds, callback: () => {
		console.log("emitting signin...")
		this.$emit("submitted", {stage: "singin"})
	    }})
	},
	keyUpHandler (e) {
	    if ( e.type != "keyup") {
		return null
	    }
	    if ( e.key != "Enter") {
		return null
	    }
	    console.log("enter pressed!")
	    this.submitClicked()
	},
	authSwitch(state) {
	    this.$emit("authswitch", state)
	}
    },
}
</script>

<style>
</style>
