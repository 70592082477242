<template>
<div class="content">
  <span v-if="ticket">
    <div class="columns">
      <div class="column is-3">

	<div class="notification">
	  <div class="has-text-weight-semibold">Owner:</div>
	  <div v-if="ticket.id">{{ ticket.owner }}</div>
	  <div v-else><i class="fas fa-sync fa-spin"></i></div>
        </div>

        <div class="notification">
	  <div class="has-text-weight-semibold">Created:</div>
	  <div v-if="ticket.id">{{ created }}</div>
	  <div v-else><i class="fas fa-sync fa-spin"></i></div>
        </div>

        <div class="notification">
	  <div class="has-text-weight-semibold">Updated:</div>
	  <div v-if="ticket.id">{{ updated }}</div>
	  <div v-else><i class="fas fa-sync fa-spin"></i></div>
        </div>

      </div>
      <div class="column is-9">
        <h1 class="title is-2">
          <span class="is-capitalized" v-if="ticket.id">{{ ticket.name }}</span>
          <span v-else><i class="fas fa-sync fa-spin"></i></span>
        </h1>
	<div class="block" contenteditable="true">
          <div class="subtitle">Description</div>
	  <textarea
	    class="textarea"
	    v-model="description"
	    @keyup="descriptionEdited"
            placeholder="ticket description"
	    ></textarea>
	</div>
        <div class="block">
          <div class="subtitle">Events</div>
          <TicketComment @submit="handleSubmitComment"/>
        </div>
        <div class="block">
	  <TicketComments :logs="logs"/>
        </div>
      </div>
    </div>
  </span>
  <span v-else>
    <p>No Ticket found...</p>
  </span>
  <button class="button my-5" @click="$router.push({name: 'Tickets'})">Back</button>
</div>
</template>

<script>
import { updateTicket } from "@/utils/tickets.js"

import TicketComments from "./TicketComments.vue"
import TicketComment from "./TicketComment.vue"


function parseTs(ts) {
    const dtFormat = new Intl.DateTimeFormat('en-GB', {
        // timeStyle: 'long',
        dateStyle: 'full',
        timeZone: 'UTC',
    });
    return dtFormat.format(new Date(ts * 1e3));
}


export default {
    props: [ "ticket", "logs" ],
    emits: [ "submitComment" ],
    components: { TicketComments, TicketComment },
    data() {
	return {
	    description: "",
	}
    },
    created() {
	console.log(this.ticket)
	if(this.ticket){
	    if (this.ticket.description) {
		this.description = this.ticket.description
	    }
	}
    },
    methods: {
	descriptionEdited(event){
	    const eventKeys = [" ", "Enter", ".", ","]
	    if (eventKeys.includes(event.key)){
		this.saveDescription()
		this.countNewWords = 0
	    }
	},
	saveDescription(){
	    console.log("saving desc...")
	    const data = {description: this.description}
	    // updateTicket(this.ticket.id, data, () => console.log("saved..."))
	},
	saveTicket(){
	    console.log("saving tick...")
	},
        handleSubmitComment(event) {
            this.$emit("submitComment", event)
        },
    },
    computed: {
	updated() {
	    if (this.ticket.id){
		return parseTs(this.ticket.updated)
	    }
	},
	created() {
	    if (this.ticket.id){
		return parseTs(this.ticket.created)
	    }
	},
    },
}
</script>

<style>

</style>
