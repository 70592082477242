<template>
<div class="Position box" @mousemove="updateMousemove">
  <h2>{{ title }}</h2>
  <span v-if="started"><p>Position: {{ x }} - {{ y }}</p></span>
  <span v-else><p>Place mouse here...</p></span>
</div>
</template>

<script>
export default {
    data() {
        return {
            started: false,
            x: -1,
            y: -1
        }
    },
    props: {
	title: {
	    type: String,
	    default: "Positional Box",
	}
    },
    methods: {
        updateMousemove(event) {
            // console.log("mouse Event!!!", event.type)
            this.started = true
            this.x = event.offsetX
            this.y = event.offsetY
        }
    }
}
</script>

