<template>
<div class="box">
  <span v-if="score > 1">this is your score: {{ score }}: {{ getRank() }}</span>
  <span v-else>press button above to play...</span>
</div>
</template>

<script>
export default {
    name: "Results",
    props: ["score"],
    methods: {
	getRank() {
	    if (this.score < 250){
		return "great"
	    } else if (this.score < 500){
		return "ok..."
	    } else {
		return "snail..."
	    }
	}
    },
}
</script>
